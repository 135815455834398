<template>
    <div>
        <div
                class="list">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "List"
    }
</script>

<style scoped>
    .list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 100%;
        text-align: left;
        margin: 16px;
    }
</style>
