<template>
    <div>
        <a class="link" :href="service.url">
            {{service.title}}
        </a>
    </div>
</template>
    
<script>
    export default {
        name: "Service",
        props: {
            service: {
                key: {type: Number},
                title: {type: String, default: 'none'},
                url: {type: String, default: 'none'}
            }
        }
    }
</script>

<style scoped>
    a {
        font-size : 18px;
        cursor    : pointer;
        color: #ffffff;
    }
</style>
