<template>
    <div>
        <div class="service-list">
            <div class="list-header">
                {{title}}
            </div>
            <List class="list-content">
                <Service class="service" v-for="service in services" :key="service.key" :service="service"/>
            </List>
        </div>
    </div>
</template>

<script>
    import Service from "./Service";
    import List from "./List";

    export default {
        name: "ServiceList",
        components: {Service, List},
        props: {
            title: {type: String, default: 'List'},
            services: Array
        }
    }
</script>

<style scoped>
    .service {
        margin: 4px;
    }

    .list-header {
        font-size: 26px;
        margin: auto 16px;
        text-align: left;
        /*color: #A9D7EE;*/
        color: #16cdbe;
        /*font-weight: bold;*/
    }

</style>
