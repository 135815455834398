<template>
    <div>
        <div class="login-block">
            <div class="logo-login">
            </div>
            <div class="title">Navigation Dashboard</div>
            <div class="login-form">
                <div v-if="error" class="login-form-row">
                    <div class="error-text">
                        {{this.errorMessage}}
                    </div>
                </div>

                <div class="login-form-row">
                    <label class="credentials user" for="username"></label>
                    <input id="username" v-model="username" type="text" placeholder="B2B portal email">
                </div>

                <div class="login-form-row">
                    <label class="credentials lock" for="password"></label>
                    <input id="password" v-model="password" type="password" placeholder="B2B portal password"
                           @keyup.enter="login"
                    >
                </div>

                <div class="login-form-row">
                    <button class="login-button" v-on:click="login">Submit</button>
                </div>

                <div class="login-form-row spacebetween">
                    <div class="remember">
                        <input type="checkbox" id="checkbox" v-model="checked">
                        <label class="remember-label" for="checkbox">Remember me</label>
                    </div>

                    <a class="rememberandforget" href="https://b2b.sense-company.com/password/reset">Forgot
                        password?</a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Login',
        methods: {
            login() {
                let email = this.username;
                let password = this.password;
                let remember = this.checked;

                let user = {
                    email,
                    password,
                    remember
                };

                this.$http.post('https://b2b.sense-company.com/api/login', user).then(resp => {
                    const token = resp.data.success['token'];
                    const userEmail = resp.data.email;

                    let loginTime = new Date();
                    localStorage.setItem('loginTime', loginTime.getTime() + '');
                    localStorage.setItem('token', token);
                    localStorage.setItem('userEmail', userEmail);

                    this.$emit('update', 'login');
                }).catch(error => {
                    this.error = true;

                    let errorCode = error.response.data.error;
                    if (errorCode === 'email_unknown' || errorCode === 'pass_unknown') {
                        this.errorMessage = 'These credentials do not match our records.';
                    } else {
                        this.errorMessage = 'Something went wrong';
                    }

                    this.password = '';

                    localStorage.removeItem('token');
                    localStorage.removeItem('userEmail');
                    localStorage.removeItem('loginTime');
                });
            },
        },
        data() {
            return {
                username: '',
                password: '',
                checked: false,
                error: false,
                errorMessage: ''
            };
        }
    };
</script>

<style scoped>
    @import url('https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css');

    .login-block {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.7);
        box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0px 30px;
        flex: 0 1 20em;
    }

    .title {
        font-size: 20px;
        margin-bottom: 15px;
        text-align: left;
        color: #16cdbe;
    }

    .login-button {
        width: 100%;
        border-radius: 4px;
        background-color: #16CDBE;
        color: #EEE;
        font-weight: bold;
        text-transform: none;
        padding: 16px;
        border: 0;
        font-family: sense-font, 'Avenir', Helvetica, Arial, sans-serif;
        cursor: pointer;
    }

    .login-form input {
        font-family: sense-font, 'Avenir', Helvetica, Arial, sans-serif;
        color: #16CDBE;

    }

    .login-form input[type = 'password'], .login-form input[type = 'text'] {
        padding: 10px;
        width: 100%;
        border-radius: 0 0.25rem 0.25rem 0;
        border: 0;
        color: inherit;
        font: inherit;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;
        cursor: text;
        background-color: #FFF;
    }

    .credentials {
        background-color: rgb(232, 240, 254);
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        padding: 1rem;
    }

    .user:before {
        content: '\f007';
        font: 20px fontawesome;
        color: #5B5B5B;
    }

    .lock:before {
        content: '\f023';
        font: 20px fontawesome;
        color: #5B5B5B;
    }

    .login-form-row {
        margin-bottom: 1.5rem;
        padding: 0;
        width: 100%;
        display: flex;
        border-radius: 8px;
    }

    .login-form {
        width: 100%;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #16CDBE;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #16CDBE;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #16CDBE;
    }

    label {
        color: white;
    }

    .spacebetween {
        justify-content: space-between;
    }

    a {
        text-decoration: none;
    }

    .rememberandforget {
        color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .remember-label {
        font-size: 14px;
        color: white;
    }

    .logo-login {
        display: flex;
        height: 200px;
        width: 100%;
        flex-grow: 1;
        margin-bottom: 15px;
        background: url("../assets/img/sense-logo-login-01.png") center no-repeat;
        background-size: contain;
    }
</style>