<template>
  <div id="app" class="noselect">
    <div v-if="isAuthenticated" class="authenticated-section">
      <span v-on:click="logout" class="logout-button">Logout</span>

      <img id="senselogo" alt="Sense Company logo" src="./assets/img/logo2.png">
      <div class="logo-text">Spreading Unexpected Smiles</div>

      <div class="list-holder">
        <div class="horizontalList">
          <ServiceList title="General" class="list" :services="generalServices"/>
          <ServiceList title="Sales" class="list" :services="salesServices"/>
          <ServiceList title="Development" class="list" :services="devServices"/>
          <ServiceList title="Marketing" class="list" :services="marketingServices"/>
          <ServiceList title="Backoffice" class="list" :services="backOfficeServices"/>
          <ServiceList title="Operation" class="list" :services="operationServices"/>
          <ServiceList title="HRM" class="list" :services="hrmServices"/>
        </div>
      </div>
    </div>

    <div v-if="!isAuthenticated" class="login-page">
      <Login @update="loginEvent" class="login-holder"></Login>
    </div>

  </div>
</template>

<script>
import ServiceList from "./components/ServiceList";
import Login from "./components/Login";

// Testing
// const allowedNotLoggedInMinutes = 1;
// const milliSecPerMinute = 60000;
// const allowedTimeMilliSecondsTest = allowedNotLoggedInMinutes * milliSecPerMinute;

const allowedNotLoggedInDays = 7;
const milliSecPerDay = 86400000;
const allowedTimeMilliSeconds = allowedNotLoggedInDays * milliSecPerDay;

export default {
  name: 'app',
  components: {
    Login,
    ServiceList,
  },
  data() {
    return {
      isAuthenticated: localStorage.getItem('token') != null,
      salesServices: [
        {key: 0, title: 'Hubspot', url: 'https://www.hubspot.com/'},
        {key: 1, title: 'Zendesk', url: 'https://sense-companyhelp.zendesk.com/'},
      ],
      marketingServices: [
        {key: 0, title: 'Scent Scraper', url: 'https://scentscraper.sense-company.com/'},
        {key: 1, title: 'Social - LinkedIn', url: 'https://www.linkedin.com/company/sense-company'},
        {
          key: 2,
          title: 'Social - Youtube',
          url: 'https://www.youtube.com/channel/UCxbZS2LapOfsurNfxgWMdrg'
        },
        {
          key: 3,
          title: 'Social - Instagram',
          url: 'https://www.instagram.com/sensecompanytilburg/'
        },
        {
          key: 4,
          title: 'Hootsuite',
          url: 'https://hootsuite.com/dashboard#/streams'
        },
        {key: 5, title: 'Social - Facebook', url: 'https://www.facebook.com/sense.company.tilburg/'},
        {
          key: 6,
          title: 'Data Studio',
          url: 'https://datastudio.google.com/reporting/1pALDxMm6plB-PtIefT9X4eqgxvUXxxQW/page/sfjO'
        },
        {
          key: 7,
          title: 'Google AdWords',
          url: 'https://ads.google.com/nav/selectaccount?euid=220945166&__u=1150075934&authuser=0&subid=nl-nl-et-g-aw-c-home-awhp_xin1_signin!o2&sourceid=emp  '
        },
        {
          key: 8,
          title: 'Google Analytics',
          url: 'https://analytics.google.com/analytics/web/#/report-home/a26931430w91091114p94780313'
        },
        {
          key: 9,
          title: 'Test Website',
          url: 'https://test.sense-company.com'
        },
        {
          key: 10,
          title: 'Partner Dashboard',
          url: 'https://partner.sense-company.com/'
        }
      ],
      backOfficeServices: [
        {key: 0, title: 'Soon TM', url: 'http://www.ishetaltijdvoorbier.nl/'},
      ],
      operationServices: [
        {key: 0, title: 'ASPIDER INSIDE', url: 'https://www.aspiderinside.com/'},
        {key: 1, title: 'Newline', url: 'https://post.jasperwireless.com/'},
        {key: 2, title: 'Simple Simon', url: 'https://nl1.simple-simon.net/'},
        {key: 3, title: 'Sendcloud', url: 'https://panel.sendcloud.sc/accounts/login/'}
      ],
      devServices: [
        {key: 0, title: 'Rituals Portal', url: 'https://rituals.sense-company.com'},
        {key: 1, title: 'Staging Rituals Portal', url: 'https://stagingrituals.sense-company.com/'},
        {key: 2, title: 'B2B Staging Portal', url: 'https://b2b.staging.sense-company.com'},
        {key: 3, title: 'Bitbucket', url: 'https://bitbucket.org/dashboard/overview'},
        {key: 4, title: 'BOT', url: 'https://bot.sense-company.com'},
        {key: 5, title: 'Jenkins', url: 'http://136.144.177.19:8010/'},
        {key: 6, title: 'Portainer', url: 'http://136.144.177.19:8001/'},
        {key: 7, title: 'Sonarqube', url: 'http://sonarqube.sense-company.com/'},
        {key: 8, title: 'Cloudflare', url: 'https://dash.cloudflare.com/'},
        {key: 9, title: 'Rituals Support Mail', url: 'https://ritualsgmail.sense-company.com/'},
        {key: 10, title: 'Staging Scent Scraper', url: 'https://scentscraper-staging.sense-company.com/'},
      ],
      generalServices: [
        {key: 0, title: 'Drive', url: 'https://drive.google.com/drive/my-drive'},
        {key: 1, title: 'Website', url: 'https://sense-company.com'},
        {key: 2, title: 'Intranet', url: 'https://intranet.sense-company.com/'},
        {key: 3, title: 'Exact Online', url: 'https://start.exactonline.nl/docs/Login.aspx?ReturnUrl=%2fdocs%2fMenuPortal.aspx'},
        {key: 4, title: 'B2B Portal', url: 'https://b2b.sense-company.com'},
        {key: 5, title: 'B2B Portal Search', url: 'https://b2b.sense-company.com/search'},
        {key: 6, title: 'JIRA', url: 'https://sensecompany.atlassian.net/secure/Dashboard.jspa'},
        {key: 7, title: 'Confluence', url: 'https://sensecompany.atlassian.net/wiki/'},
        {key: 7, title: 'WVK Dashboard', url: 'https://wvk.sense-company.com/'},
      ],
      hrmServices: [
        {key: 0, title: 'Ontdekkracht', url: 'https://ontdekkracht.nmbrs.nl/'},
      ]
    }
  },
  methods: {
    setIsAuthenticated() {
      let token = localStorage.getItem('token');
      this.isAuthenticated = token != null;
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userEmail');
      this.setIsAuthenticated();
      this.$forceUpdate();
    },
    loginEvent() {
      this.setIsAuthenticated();
      this.$forceUpdate();
    },
    checkLoggedInTime() {
      if (this.isAuthenticated) {
        let now = new Date().getTime();
        let loginTime = parseInt(localStorage.getItem('loginTime'));
        let diff = now - loginTime;

        if (diff > allowedTimeMilliSeconds) {
          this.invalidateUserSession();
        } else {
          localStorage.setItem('loginTime', now + '');
        }
      }
    },
    invalidateUserSession() {
      localStorage.removeItem('token');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('loginTime');
      this.setIsAuthenticated();
      this.$forceUpdate();
    }
  },
  beforeMount() {
    this.setIsAuthenticated();
    this.checkLoggedInTime();
  }
}
</script>

<style>
@font-face {
  font-family: "sense-font";
  src: url("./assets/fonts/GothamRounded-Book.eot");
  src: url("./assets/fonts/GothamRounded-Book.eot?iefix") format("embedded-opentype"),
  url("./assets/fonts/GothamRounded-Book.woff2") format('woff2'),
  url("./assets/fonts/GothamRounded-Book.woff") format('woff'),
  url('./assets/fonts/GothamRounded-Book.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  /*background: url("assets/img/backgrounds/pink-flower.jpg") no-repeat center center fixed;*/
  background-image: url("assets/img/backgrounds/blue-flower.jpg");
  background-size: cover;
  height: 100%;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  body {
    background-size: auto;
  }
}

html {
  height: 100%;
}

#app {
  font-family: sense-font, 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2C3E50;
  max-width: 100%;
  align-items: center;
  height: 100%;
}

.logout-button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px 16px;
  color: black;
  cursor: pointer;
}


#senselogo {
  max-width: 100%;
  margin-top: 100px;
  /*margin-bottom: 100px;*/
  margin-bottom: 35px;
}

.logo-text {
  font-size: 30px;
  margin-bottom: 35px;
  color: white;
}

.list-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontalList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  /*background: linear-gradient(transparent, #2C3E50);*/
  /*background-color: black;*/
  /*opacity: 0.6;*/
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 24px;
  padding: 24px;
}


a {
  color: #28CDBE;
  text-decoration: none;
}

.login-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}
</style>
